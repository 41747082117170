import 'bootstrap'

import Swiper, { Autoplay, Pagination } from 'swiper'

document.querySelectorAll('.Auth__modal').forEach((element) => {
  element.addEventListener('click', (event) => {
    if (!event.target.closest('.Auth__modal-window')) {
      event.target.closest('.Auth__modal').classList.remove('Auth__modal--in')
    }
  })
})

document.querySelectorAll('.Auth__modal-close').forEach((element) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()
    event.target.closest('.Auth__modal').classList.remove('Auth__modal--in')
  })
})

document.querySelectorAll('[data-modal-id]').forEach((element) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()

    const modalId = event.currentTarget.dataset.modalId
    const modalEl = document.getElementById(modalId)
    const modalActiveClassName = 'Auth__modal--in'

    modalEl && modalEl.classList.add(modalActiveClassName)
  })
})

Swiper.use([Autoplay, Pagination])

new Swiper('.swiper', {
  loop: true,
  autoplay: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
})
